
import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { HashLoader } from 'react-spinners'
import { theme } from '../../../../tailwind.config';
import Logo from '../../../images/Logo_Primary.svg';
import { Auth, fetchUser } from '../../../modules/auth_module';

const AddPage = () => {
    const auth = Auth.use()
    useEffect(() => {
        if (!document.cookie.includes('kodama')) {
            navigate('/app/login')
        }
        fetchUser();
    }, [])


    const [form, setForm] = useState({orgName: ''});

    return (
        <div className="grid grid-cols-1 lg:grid-cols-3">
                <div className="lg:col-start-2 lg:col-end-2 mt-8 mb-5 flex justify-center max-h-12">
                    <img src={Logo} alt="Kodama"/>
                </div>
                <div className="lg:col-start-2 lg:col-end-2 shadow-lg rounded-xl p-5 border min-h-1/2 border-gray-200">
                    {auth.loading && (
                        <div className="h-72 flex items-center justify-center">
                            <HashLoader color={theme.extend.colors.primary.DEFAULT}/>
                        </div>
                    )}
                    {auth.loggedIn && (
                        <div className="h-72 flex flex-col">
                            <div>
                                <h2 className="text-xl font-bold">Create Organization</h2>
                                <p className="text-sm">New Organization</p>
                            </div>
                            <div className="mt-4">
                                <label htmlFor="orgName" className="text-sm">Organization Name</label>
                                <input 
                                    name="orgName" 
                                    value={form.email}
                                    onChange={(e) => setForm({...form, email: e.target.value})}
                                    className="w-full p-1 shadow-inner rounded-sm h-8 border border-gray-300"
                                />
                            </div>
                            <div className="flex-1"/>
                            <div className="mt-3 flex w-full justify-end">
                                <div className="w-full">
                                    <button

                                        className="py-2 px-5 w-full rounded bg-primary text-white"
                                    >
                                        Login
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
    )
}

export default AddPage;
